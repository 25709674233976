<template>
  <div class="agent-contact" v-if="AgentData">
    <address>
      <base-icon name="apps/phone" size="16px" color="var(--slate-400)" />
      <p class="agent-contact-phonelink">{{ maskphone(AgentData.agent.phone) }}</p>
    </address>
    <address v-if="AgentData.agent.telegram">
      <base-icon name="apps/telegram" size="16px" color="var(--slate-400)" />
      <a :href="`tg://resolve?domain=${AgentData.agent.telegram}`" class="agent-contact-link">{{
        AgentData.agent.telegram
      }}</a>
    </address>

    <address v-if="AgentData.agent.email">
      <base-icon name="apps/email" size="16px" color="var(--slate-400)" />
      <a
        :href="`mailto:${AgentData.agent.email}?subject=Пишу по поводу презентации и бла бла бла`"
        class="agent-contact-link"
        >{{ AgentData.agent.email }}</a
      >
    </address>

    <address v-if="AgentData.agent.instagram">
      <base-icon name="apps/instagram" size="16px" color="var(--slate-400)" />
      <a
        :href="`https://www.instagram.com/${AgentData.agent.instagram}`"
        target="_blank"
        class="agent-contact-link"
        >{{ AgentData.agent.instagram }}</a
      >
    </address>

    <address v-if="AgentData.agent.whatsapp">
      <base-icon name="apps/whatsapp" size="16px" color="var(--slate-400)" />
      <a
        :href="`https://wa.me/${AgentData.agent.whatsapp}`"
        target="_blank"
        class="agent-contact-link"
        >{{ AgentData.agent.whatsapp }}</a
      >
    </address>

    <address v-if="AgentData.agent.viber">
      <base-icon name="apps/viber" size="16px" color="var(--slate-400)" />
      <a :href="`viber://chat?number=${AgentData.agent.viber}`" class="agent-contact-link">{{
        AgentData.agent.viber
      }}</a>
    </address>
  </div>
</template>

<script setup lang="ts">
import maskphone from '~/helpers/MaskPhone'
import { PresentationStandalone } from '~/interfaces/tsPresentation'

const props = defineProps({
  AgentData: {
    required: true,
    type: Object as () => PresentationStandalone,
  },
})

const AgentData = computed(() => props.AgentData)
</script>

<style scoped lang="scss">
.agent {
  &-contact {
    display: grid;
    grid-template-columns: 1fr;
    justify-self: left;
    row-gap: 14px;
    address {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
    }
    a,
    p {
      height: 16px;
      display: flex;
      gap: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 2px;
    }
    &-phonelink {
      color: var(--slate-800, #2c2933);
      margin: 0;
      cursor: default;
    }
    &-link {
      color: var(--violet-400, #6b50b2);
      text-decoration-line: underline;
    }
  }
}
</style>
